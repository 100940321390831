var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title:
                  _vm.appName +
                  " - Service Location: " +
                  (_vm.serviceLocation.name || "-")
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: {
                    name: "services-show-locations",
                    params: { service: _vm.serviceLocation.service_id }
                  }
                }
              },
              [_vm._v("Back to service")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "two-thirds" } },
                      [
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("View service location")
                        ]),
                        _vm.updated
                          ? _c("gov-inset-text", [
                              _vm._v(
                                "Service Location " +
                                  _vm._s(_vm.serviceLocation.name || "") +
                                  " has been\n            updated"
                              )
                            ])
                          : _vm._e(),
                        _c("service-location-details", {
                          attrs: { "service-location": _vm.serviceLocation }
                        }),
                        _vm.auth.isServiceAdmin(_vm.serviceLocation.service_id)
                          ? [
                              _c("gov-body", [
                                _vm._v(
                                  "Please be certain of the action before deleting a service\n              location"
                                )
                              ]),
                              _c("gov-section-break", { attrs: { size: "l" } }),
                              _c("ck-delete-button", {
                                attrs: {
                                  resource: "service location",
                                  endpoint:
                                    "/service-locations/" +
                                    this.serviceLocation.id
                                },
                                on: { deleted: _vm.onDelete }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm.auth.isServiceAdmin(_vm.serviceLocation.service_id)
                      ? _c(
                          "gov-grid-column",
                          {
                            staticClass: "text-right",
                            attrs: { width: "one-third" }
                          },
                          [
                            _c(
                              "gov-button",
                              {
                                attrs: {
                                  to: {
                                    name: "service-locations-edit",
                                    params: {
                                      serviceLocation: _vm.serviceLocation.id
                                    }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Edit service location\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }