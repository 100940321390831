var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Name")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.name || "-"))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Address line 1")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.location.address_line_1))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Address line 2")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  _vm._s(_vm.serviceLocation.location.address_line_2 || "-")
                )
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Address line 3")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  _vm._s(_vm.serviceLocation.location.address_line_3 || "-")
                )
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("City")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.location.city))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("County")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.location.county))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Postcode")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.location.postcode))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Country")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.serviceLocation.location.country))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Accessibility")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c("gov-list", [
                    _c("li", [
                      _vm._v(
                        "\n            Wheelchair access:\n            " +
                          _vm._s(
                            _vm.serviceLocation.location.has_wheelchair_access
                              ? "Yes"
                              : "No"
                          ) +
                          "\n          "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            Induction loop system:\n            " +
                          _vm._s(
                            _vm.serviceLocation.location.has_induction_loop
                              ? "Yes"
                              : "No"
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Regular opening hours")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gov-list",
                    [
                      _vm._l(
                        _vm.serviceLocation.regular_opening_hours,
                        function(regularOpeningHour, index) {
                          return _c("li", {
                            key: index,
                            domProps: {
                              textContent: _vm._s(
                                _vm.formatRegularOpeningHour(regularOpeningHour)
                              )
                            }
                          })
                        }
                      ),
                      _vm.serviceLocation.regular_opening_hours.length === 0
                        ? _c("li", [
                            _vm._v(
                              "\n            No regular opening hours have been specified for this service\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Holiday opening hours")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gov-list",
                    [
                      _vm._l(
                        _vm.serviceLocation.holiday_opening_hours,
                        function(holidayOpeningHour, index) {
                          return _c("li", {
                            key: index,
                            domProps: {
                              textContent: _vm._s(
                                _vm.formatHolidayOpeningHour(holidayOpeningHour)
                              )
                            }
                          })
                        }
                      ),
                      _vm.serviceLocation.holiday_opening_hours.length === 0
                        ? _c("li", [
                            _vm._v(
                              "\n            No holiday opening hours have been specified for this service\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Image")
              ]),
              _c("gov-table-cell", [
                _c("img", {
                  staticClass: "ck-logo",
                  attrs: {
                    src: _vm.apiUrl(
                      "/service-locations/" +
                        _vm.serviceLocation.id +
                        "/image.png?v=" +
                        _vm.serviceLocation.created_at
                    ),
                    alt: "Service location image"
                  }
                })
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Map")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gmap-map",
                    {
                      staticStyle: { width: "100%", height: "20rem" },
                      attrs: {
                        center: {
                          lat: _vm.serviceLocation.location.lat,
                          lng: _vm.serviceLocation.location.lon
                        },
                        zoom: 13,
                        "map-type-id": "roadmap"
                      }
                    },
                    [
                      _c("GmapMarker", {
                        attrs: {
                          position: {
                            lat: _vm.serviceLocation.location.lat,
                            lng: _vm.serviceLocation.location.lon
                          },
                          clickable: false,
                          draggable: false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }